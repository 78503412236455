<template>
  <div>
    <h1 class="not-found-404">
      <img src="@/assets/404.png" alt="xgb" />
      <br />
      <h1>页面没找到或您没有此页面权限</h1>
      <br />
      <a style="font-size: 28px" href="javascript:;" @click="$router.go(-1)">GO BACK</a>
      &nbsp;&nbsp;
      <a
        style="font-size: 28px"
        href="javascript:;"
        @click="$router.push({name: 'Index'})"
      >HOME PAGE</a>
    </h1>
  </div>
</template>
<script>
export default {
  name: '404'
}
</script>
<style lang="scss" scoped>
.not-found-404 {
  font-size: 40px;
  font-weight: 500;
  padding: 100px 0;
  text-align: center;
  img {
    display: block;
    margin: 100px auto 20px;
    width: 400px;
  }
}
</style>
